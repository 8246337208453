import {
  Person,
  WithContext,
  EducationalOrganization,
  Occupation,
} from "schema-dts";

const education: EducationalOrganization = {
  "@type": "CollegeOrUniversity",
  name: "The University of Michigan",
  location: "Ann Arbor, MI",
};

const occupation: Occupation = {
  "@type": "Occupation",
  name: "Software Engineer",
};

export const PersonalSchema: WithContext<Person> = {
  "@context": "https://schema.org",
  // @ts-ignore
  "@type": "Person",
  "@id": "https://chriscerk.com/#person",
  name: "Christopher Cerk",
  alternateName: "Chris Cerk",
  givenName: "Christopher",
  familyName: "Cerk",
  gender: "Male",

  nationality: "American",
  sameAs: [
    "https://github.com/chriscerk",
    "https://www.linkedin.com/in/chriscerk/",
    "https://empirical.engineer/",
  ],

  // Employment
  jobTitle: "Software Engineer",
  worksFor: ["Included Health"],

  // Occupation
  hasOccupation: occupation,

  // Edu
  alumniOf: education,

  // Skills
  knowsAbout: [
    ".NET",
    "Angular",
    "C#",
    "Cassandra",
    "containers",
    "distributed systems",
    "frontend",
    "Grafana",
    "interaction design",
    "Kafka",
    "Kubernetes",
    "leadership",
    "Mongo",
    "Prometheus",
    "React",
    "software architecture",
    "software",
    "Typescript",
    "UX",
  ],

  //Other
  // memberOf: "US Sailing",
};

export const constants = {
  PersonalInfo: PersonalSchema,
};

export default constants;
