import chriscerkFace from "./chriscerk-face.webp";
import "./App.css";
import { Person } from "schema-dts";
import { JsonLd } from "react-schemaorg";
import { constants } from "./constants";

function App() {
  return (
    <main>
      <JsonLd<Person> item={constants.PersonalInfo} />
      <section>
        <figure>
          <img
            className="chris-face"
            itemProp="image"
            alt="Christopher's Face"
            src={chriscerkFace}
          />
        </figure>
        <div className="welcome-wrapper">
          <div className="spinner">
            <svg viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">
              <rect width="200" height="200" x="50" y="50" />
            </svg>
            <svg viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">
              <rect width="200" height="200" x="50" y="50" />
            </svg>
            <svg viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">
              <rect width="200" height="200" x="50" y="50" />
            </svg>
            <svg viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">
              <rect width="200" height="200" x="50" y="50" />
            </svg>
            <svg viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">
              <rect width="200" height="200" x="50" y="50" />
            </svg>
            <svg viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">
              <rect width="200" height="200" x="50" y="50" />
            </svg>
            <svg viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">
              <rect width="200" height="200" x="50" y="50" />
            </svg>
            <svg viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">
              <rect width="200" height="200" x="50" y="50" />
            </svg>
          </div>
          <h1>Hi, my name is Chris.</h1>
        </div>
      </section>
      <section>
        <p>
          I'm writing about software at{" "}
          <a
            href="https://empirical.engineer/?ref=chriscerk-bio"
            title="Discussing the efficiency of code, systems, processes, user-experiences, and teams"
          >
            empirical.engineer
          </a>
          .
        </p>
        <p>
          Currently, I am a software engineer at{" "}
          <a href="https://includedhealth.com">Included Health</a>.
        </p>
        <p>
          I designed and built a{" "}
          <a
            title="Off-grid housing during my time at the University of Michigan"
            href="https://www.google.com/search?q=chris%20cerk%20tiny%20house"
          >
            tiny house
          </a>{" "}
          which I lived in for two years off-grid.
        </p>
        <p>
          Previously, I was a Software Engineering Manager at{" "}
          <a
            href="https://www.hagerty.com"
            title="Auto-enthusiast & insurance company"
          >
            Hagerty
          </a>
        </p>
      </section>
      <footer>
        <span>Christopher Cerk | {new Date().getFullYear()}</span>
      </footer>
    </main>
  );
}

export default App;
